<template>
  <el-dialog
      title="系列列表"
      width="80%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-col :span="24">
        <el-table
            :row-style="{height: '38px'}"
            :cell-style="{padding: '0'}"
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
            :data="seriesTable.seriesList"
            border
            max-height="700"
            :default-sort="{prop: 'id', order: 'ascending'}"
            style="width: 99%;margin-top: 33px;">
          <el-table-column prop="brandName" label="系列图" align="center">
            <template slot-scope="scope">
              <img :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+scope.row.seriesImg"
                   style="max-height: 100px"
                   @click="openImage(scope.row.seriesImg)"
                   alt="系列图">
            </template>
          </el-table-column>
          <el-table-column prop="brandName" label="品牌" align="center"/>
          <el-table-column prop="seriesName" label="系列名" align="center"/>
          <el-table-column prop="seriesNum" label="系列编号" align="center"/>
          <el-table-column width="350" align="center" fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-button type="warning" size="mini" @click="openSeriesEdit(scope.row)">编辑</el-button>
                <el-button type="success" size="mini" @click="()=>{seriesId=scope.row.id;drawer=true;}">添加图片</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top: 5px">
          <el-pagination
              background
              @size-change="seriesHandleSizeChange"
              @current-change="seriesHandleCurrentChange"
              :page-size="this.seriesTable.limit"
              layout="prev, pager, next,total, jumper, sizes"
              :total="seriesTable.total">
          </el-pagination>
        </el-row>
      </el-col>
    </el-row>

    <seriesEdit v-if="dialog.seriesEditState" :series="dialog.series" :state="dialog.seriesEditState" @close="seriesClose"/>
    <el-drawer
        title="上传图片"
        :visible.sync="drawer"
        :append-to-body="true">
      <el-row>
        <el-col :span="16" :offset="6">
          <el-button type="primary" icon="el-icon-upload2" @click="addImage" :loading="loading">上 传</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top: 30px">
        <el-col :span="16" :offset="6">
          <div>
            <el-row style="margin-top: 30px">
              <UploadImages @files="getFiles" :img-size="1"/>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </el-dialog>
</template>

<script>

import seriesEdit from "@/pages/store/brand-list/store-series-edit.vue";
import UploadImages from "@/components/image/uploadImages.vue";

export default {
  name: "store-series-list",
  data() {
    return {
      seriesTable: {
        seriesList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      tenantCrop: localStorage.getItem("tenantCrop"),
      dialogState: this.state,
      dialog: {
        seriesEditState: false,
        seriesNumAddState:false,
        seriesNumEditState:false,
        series: {},
        seriesNumber:{},
      },

      loading: false,
      drawer: false,
      seriesId:"",
      imageNames: [],
      styleImage: {
        files: [],
      },
    }
  },
  components: {
    UploadImages,
    seriesEdit
  },
  created() {
    this.querySeriesList();
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    storeBrand:{
      type: Object,
      required: true,
    }
  },
  methods: {
    //查询系列列表
    querySeriesList: function () {
      this.$axios({
        method: "GET",
        url: "/storeSeries/queryList",
        params: {
          tenantCrop: this.tenantCrop,
          storeBrandId: this.storeBrand.id,
        }
      }).then(response => {
        this.seriesTable.seriesList = response.data.data;
      })
    },
    //添加图片
    addImage() {
      if (this.styleImage.files.length<1) {
        this.$message({
          showClose: true,
          message: "请选择图片",
          type: 'warning',
          duration: 1500,
        })
        return
      }
      this.loading = true
      this.uploadStyleImage().then(value => {
        if (!value) {
          this.$message({
            showClose: true,
            message: "图片上传发生错误,请检查后重新上传",
            type: 'error',
            duration: 1500,
          })
        } else {
          this.$axios({
            method: "PUT",
            url: "/storeSeries/addSeriesImage",
            data: {
              id:this.seriesId,
              seriesImg:this.imageNames[0]
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "图片添加成功" :response.data.msg,
              type: flag? 'success' :'error',
              duration: 1000,
            })
            if (flag) {
              this.drawer = false
              this.querySeriesList()
              this.styleImage.files=[];
              this.imageNames=[]
            }
            this.loading = false
          })
        }
      })
    },
    //从子组件获取file
    getFiles(file) {
      this.styleImage.files = file;
    },
    //构建多文件上传时的格式
    buildFiles() {
      let buildFiles = [];
      this.styleImage.files.forEach(k => {
        let arr = k.name.split(".");
        let fileName = this.$md5(arr[0] + new Date()) + "." + arr[arr.length - 1];
        this.imageNames.push(fileName);
        buildFiles.push({
          Bucket: this.$upload.getBucketName(this.$upload.clothesImage), /* 填入您自己的存储桶，必须字段 */
          Region: this.$upload.getRegion(),  /* 存储桶所在地域，例如ap-beijing，必须字段 */
          Key: fileName,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          Body: k.raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
        })
      })
      return buildFiles;
    },
    uploadStyleImage: function () {
      return new Promise((resolve, reject) => {
        if (this.styleImage.files < 1) {
          return resolve(true)
        }
        //构建腾讯需要的文件对象
        let buildFlies = this.buildFiles();
        this.$upload.getTemporaryKey(this.$upload.clothesImage).uploadFiles({
          files: buildFlies,
          SliceSize: 1024 * 1024 * 50, //图片大于50M则分块上传
        }, function (err, data) {
          let error = false;
          data.files.forEach(k => {
            //如果不等于null 则说明图片上传有误 则返回true
            error = k.error !== null ? true : error;
          })
          if (err !== null || error) {
            this.$message.error("上传失败,请刷新重试!")
            resolve(false)
          } else {
            resolve(true)
          }
        })

      })
    },

    //打开系列编辑
    openSeriesEdit(data) {
      this.dialog.seriesEditState = true
      this.dialog.series = data
    },
    //打开上传图片
    openSeriesImg(){

    },
    //放大图片
    openImage(link) {
      this.$viewerApi({
        images: ['https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/' + link],
      })
    },
    //更改每页长度
    seriesHandleSizeChange: function (val) {
      this.seriesTable.limit = val;
      this.querySeriesList();
    },
    seriesHandleCurrentChange(val) {
      this.seriesTable.page = val
      this.querySeriesList()
    },

    seriesClose: function (state) {
      this.dialog.seriesEditState = state;
      this.querySeriesList();
    },
    close: function () {
      this.$emit("close", false);
    },
  }
}
</script>

<style scoped>

</style>